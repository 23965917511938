<template>
  <secondary-page-layout title="Восстановление пароля">
    <div class="py-3" v-if="!showVerify">
      <ValidationObserver ref="form" v-slot="{invalid}">
        <div class="form-inner">
          <div class="form-group">
            <input-field
                v-if="!otpChecked"
                :disabled="disabledPhone"
                label="Номер телефона"
                v-model="form.phone_number"
                field-type="tel"
                :is-block="true"
                rules="required|min:10"
                name="phone_number"
                addition="Номер на который был зарегестрирован аккаунт"
                mask="+7(###) ###-##-##"/>
            <input-field
                v-if="otpChecked"
                label="Пароль"
                field-type="password"
                rules="required|min:8"
                :is-block="true"
                name="password"
                addition="*Пароль должен содержать минимум 8 символов"
                v-model="form.password"/>
            <span v-if="error" class="text-danger">{{ error }}</span>
          </div>
            <button v-if="!otpChecked" class="primary-btn bottom-page-btn" :disabled="invalid" @click="checkPhone">Отправить</button>
            <button v-if="otpChecked" class="primary-btn bottom-page-btn" :disabled="invalid" @click="resetPassword">Продолжить</button>
        </div>
      </ValidationObserver>
    </div>
    <otp-verify v-else v-model="code" @submit="verifyCode" :error-message="errorMessage"></otp-verify>
  </secondary-page-layout>
</template>

<script>
import InputField from "../../components/BuyPolicy/InputField";
import RegistrationErrorModal from "../../components/Modals/RegistrationErrorModal";
import {verifyRegistrationOtp, verifyResetPasswordOtp} from "../../api/otp";
import eventLogger from "../../services/eventLogger";
import OtpVerify from "../../components/OTP/OtpVerify";
import {mapActions} from "vuex";
export default {
  name: "ResetPassword",
  components: {
    InputField,
    RegistrationErrorModal,
    OtpVerify
  },
  data() {
    return {
      form: {
        phone_number: '+7(',
        password: '',
        token: null,
      },
      verifyToken: null,
      otpChecked: false,
      showVerify: false,
      error: null,
      disabledPhone: false,
      errorMessage: '',
      code: '',
    }
  },
  methods: {
    ...mapActions(['signIn']),
    async checkPhone() {
      const loader = this.$loading.show();
      try {
        const {data} = await window.axios.post('/auth/forgot-password',
            {
              phone_number: this.form.phone_number
            }
        )
        loader.hide();
        this.disabledPhone = true;
        this.showVerify = true;
      } catch (err) {
        loader.hide();
        const {data} = err.response;
        const errorsList = Object.values(data.errors);
        const errors = [].concat.apply([], errorsList);
        this.$modal.show(RegistrationErrorModal, {errorsList: errors}, this.$modalConfig);
        throw err
      } finally {
        loader.hide();
      }
    },
    async resetPassword() {
      const loader = this.$loading.show();
      try {
        const { data } = await window.axios.post('/auth/verify-reset', {
          phone_number: this.form.phone_number,
          token: this.verifyToken,
          password: this.form.password,
          device_id: window.DEVICE_ID
        })

        loader.hide();
        await this.$store.commit('SET_ACCESS_TOKEN', data)
        eventLogger('login_success')
        this.error = null;
        window.location.href = '/';
      } catch(err) {
        loader.hide();
      }
    },
    async verifyCode(pin) {
      const loader = this.$loading.show();
      try {
        const {data} = await window.axios.post('/auth/reset-password',
            {
              phone_number: this.form.phone_number,
              code: pin
            }
        )
        this.verifyToken = data.token;
        this.showVerify = false;
        this.otpChecked = true;
      } catch (err) {
        const { data, status } = err.response
        if (status === 400) {
          this.errorMessage = data.message;
        }
      } finally {
        loader.hide()
      }
    },
  },
}
window.setDeviceId = function(DEVICE_ID) {
  window.DEVICE_ID = DEVICE_ID
}
</script>

<style scoped>

</style>
